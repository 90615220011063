import * as React from 'react';
import { graphql } from 'gatsby';

import { ReferenceEntityQuery } from '../../types/reference';
import Markdown from '../../components/Markdown';
import Heading from '../../components/Heading';
import DocumentationLayout from '../../components/DocumentationLayout';
import TableOfContents from '../../components/TableOfContents';
import Link from '../../components/Link';

function Enumeration({
  location,
  name,
  excerpt,
  description,
  tags,
  mdx,
  members,
}: ReferenceEntityQuery) {
  return (
    <DocumentationLayout
      location={location}
      title={name}
      tags={tags}
      excerpt={excerpt}
      description={<Markdown.Raw>{description}</Markdown.Raw>}
      aside={
        <TableOfContents
          contents={[
            {
              url: Heading.slugify('Members'),
              title: 'Members',
              items: members.map(member => ({
                url: Heading.slugify(member.name),
                title: member.name,
              })),
            },
          ]}
        />
      }
    >
      <div data-kind="enum">
        <Markdown.Raw>{mdx}</Markdown.Raw>
        <Heading as="h2" id="members">
          Members
        </Heading>
        <ul className="text-base mb-4" style={{ columnCount: 3 }}>
          {members.map(member => (
            <li
              className="pb-1 border-b dark:border-gray-800 py-1 px-2 font-mono"
              key={member.name}
              style={{ breakInside: 'avoid-column' }}
            >
              <Link to={Heading.slugify(member.name)}>{member.name}</Link>
            </li>
          ))}
        </ul>
        {members.map(member => (
          <div key={member.name} className="border dark:border-gray-800 mt-10 rounded">
            <div className="ml-8">
              <Heading id={member.name} as="h3">
                {member.name}
              </Heading>
            </div>
            <div className="px-4">
              <Markdown.Raw>{member.description}</Markdown.Raw>
              <Markdown.Raw>{member.mdx}</Markdown.Raw>
            </div>
          </div>
        ))}
      </div>
    </DocumentationLayout>
  );
}

export const query = graphql`
  fragment Enumeration on ReferenceEntity {
    members {
      defaultValue
      name
      mdx
      description
    }
  }
`;

export default Enumeration;
