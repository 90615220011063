import * as React from 'react';
import { graphql } from 'gatsby';
// import TableOfContents from '../components/TableOfContents';
// import Markdown from '../components/Markdown';
import { PageQuery } from '../types/reference';

import Enumeration from './reference/Enumeration';
import Method from './reference/Method';
import Interface from './reference/Interface';
import TypeAlias from './reference/TypeAlias';

interface Props {
  location: Location;
  data: PageQuery;
}

function ReferenceEntityTemplate({ location, data }: Props) {
  const { entity } = data;

  switch (entity.kind) {
    case 'Enumeration':
      return <Enumeration {...entity} location={location} />;
    case 'Method':
      return <Method {...entity} location={location} />;
    case 'Interface':
      return <Interface {...entity} location={location} />;
    case 'Type alias':
      return <TypeAlias {...entity} location={location} />;
    default:
      console.warn(`Attempted to render reference kind "${entity.kind}" but no renderer exists.`);
      return null;
  }
}

export const pageQuery = graphql`
  query($id: String!) {
    entity: referenceEntity(id: { eq: $id }) {
      kind
      name
      excerpt
      description
      mdx
      tags {
        tag
        text
      }

      # Entities
      ...Enumeration
      ...Method
      ...Interface
      ...TypeAlias
    }
  }
`;

export default ReferenceEntityTemplate;
