import * as React from 'react';
import { graphql } from 'gatsby';
import { icons } from '@invertase/ui';

import { Parameter, ReferenceEntityQuery } from '../../types/reference';
import Markdown from '../../components/Markdown';
import DocumentationLayout from '../../components/DocumentationLayout';
import Reference from '../../components/Reference';

const { Checkmark, Close } = icons;

function Method({ location, name, signatures }: ReferenceEntityQuery) {
  const signature = signatures[0];

  return (
    <DocumentationLayout
      location={location}
      title={name}
      tags={signature.tags}
      excerpt={signature.excerpt}
      description={<Markdown.Raw>{signature.description}</Markdown.Raw>}
    >
      <br />
      <Reference.Method name={name} parameters={signature.parameters} type={signature.type} />
      <br />
      <Markdown.Raw>{signature.mdx}</Markdown.Raw>
      <br />
      <Parameters parameters={signature.parameters} />
    </DocumentationLayout>
  );
}

function Parameters({ parameters }: { parameters?: Parameter[] }) {
  if (!parameters?.length) {
    return null;
  }

  return (
    <table>
      <thead>
        <tr>
          <th>Property</th>
          <th>Description</th>
          <th>Optional</th>
        </tr>
      </thead>
      <tbody>
        {parameters.map(parameter => (
          <tr key={parameter.name}>
            <td>
              <code>{parameter.name}</code>
            </td>
            <td className="px-6">
              <Markdown.Raw>{parameter.mdx}</Markdown.Raw>
            </td>
            <td className="pl-8" style={{ width: 100 }}>
              {parameter.optional && <Checkmark size={16} />}
              {!parameter.optional && <Close size={16} className="text-gray-500" />}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export const query = graphql`
  fragment Method on ReferenceEntity {
    signatures {
      excerpt
      description
      mdx

      tags {
        tag
        text
      }

      type
      parameters {
        name
        mdx
        optional
        type
      }
    }
  }
`;

export default Method;
