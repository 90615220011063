import * as React from 'react';
import { graphql } from 'gatsby';

import { ReferenceEntityQuery } from '../../types/reference';
import Markdown from '../../components/Markdown';
import Heading from '../../components/Heading';
import DocumentationLayout from '../../components/DocumentationLayout';
import TableOfContents from '../../components/TableOfContents';
import Reference from '../../components/Reference';
import Tags from '../../components/Tags';

function Interface({
  location,
  name,
  excerpt,
  description,
  tags,
  mdx,
  properties,
}: ReferenceEntityQuery) {
  const toc = [];

  if (properties.length) {
    toc.push({
      url: Heading.slugify('Properties'),
      title: 'Properties',
      items: properties.map(property => ({
        url: Heading.slugify(property.name),
        title: property.name,
      })),
    });
  }

  return (
    <DocumentationLayout
      location={location}
      title={name}
      tags={tags}
      excerpt={excerpt}
      description={<Markdown.Raw>{description}</Markdown.Raw>}
      aside={<TableOfContents contents={toc} />}
    >
      <div data-kind="interface">
        <Markdown.Raw>{mdx}</Markdown.Raw>
        {!!properties.length && (
          <>
            <div className="mt-10 pb-4">
              <Heading as="h1" mask="h2" id="properties">
                Properties
              </Heading>
            </div>
            {properties.map(property => (
              <div key={property.name} className="border dark:border-gray-800 mt-10 rounded-lg">
                <div className="flex items-center ml-8 pr-4">
                  <Heading id={property.name} as="h2" className="flex-1">
                    {property.name}
                  </Heading>
                  {!!property.tags.length && <Tags tags={property.tags} />}
                </div>
                <div className="px-4 pb-3 ">
                  <Markdown.Raw>{property.description}</Markdown.Raw>
                  <Reference.Property
                    name={property.name}
                    type={property.type}
                    optional={property.optional}
                  />
                  <Markdown.Raw>{property.mdx}</Markdown.Raw>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </DocumentationLayout>
  );
}

export const query = graphql`
  fragment Interface on ReferenceEntity {
    properties {
      name
      type
      optional
      description
      mdx
      tags {
        tag
        text
      }
    }
  }
`;

export default Interface;
