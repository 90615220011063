import * as React from 'react';
import { graphql } from 'gatsby';

import { ReferenceEntityQuery } from '../../types/reference';
import DocumentationLayout from '../../components/DocumentationLayout';
import Markdown from '../../components/Markdown';
import Reference from '../../components/Reference';

function TypeAlias({
  location,
  name,
  excerpt,
  description,
  tags,
  mdx,
  type,
}: ReferenceEntityQuery) {
  return (
    <DocumentationLayout
      location={location}
      title={name}
      tags={tags}
      excerpt={excerpt}
      description={<Markdown.Raw>{description}</Markdown.Raw>}
    >
      <div data-kind="interface">
        <Reference.Alias type={type} />
        <Markdown.Raw>{mdx}</Markdown.Raw>
      </div>
    </DocumentationLayout>
  );
}

export const query = graphql`
  fragment TypeAlias on ReferenceEntity {
    type
  }
`;

export default TypeAlias;
