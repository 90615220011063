import * as React from 'react';
import cx from 'classnames';
import styled, { css } from 'styled-components';
import slugify from 'slugify';
import { icons } from '@invertase/ui';

import Link from './Link';

const { Link: LinkIcon } = icons;

interface Props {
  as: Headings;
  mask?: Headings; // render as a different element but keep styling of original
  children: React.ReactNode;
  className?: string;
  id?: string;
}

export type Headings = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

const base = 'text-base md:text-lg';

const size = {
  h1: 'text 2-xl md:text-3xl',
  h2: 'text-xl md:text-2xl',
  h3: base,
  h4: base,
  h5: base,
  h6: base,
};

interface StyledProps {
  'data-id'?: string;
}

const Element = styled.h1<StyledProps>(
  props => css`
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    left: ${props['data-id'] ? `-25px` : 0};

    span.anchor {
      margin-top: -6rem;
      padding-bottom: 6rem;
      display: block;
    }

    a {
      margin-right: 0.5rem;
      opacity: 0.4;
      transition: opacity 0.2s;

      &:hover {
        opacity: 1;
      }
    }
  `,
);

function Heading({ as, mask, children, className, id }: Props) {
  const hash = Heading.slugify(id);

  return (
    <Element
      data-id={id}
      as={mask || as}
      className={cx('font-semibold tracking-wide leading-tight mb-4 mt-4', className, size[as])}
    >
      {!!id && <span className="anchor" id={hash.replace('#', '')} />}
      <Link to={hash} className="text-purple-400 dark:text-white hover:underline">
        <LinkIcon size={16} />
      </Link>
      {children}
    </Element>
  );
}

Heading.slugify = (str?: string): string => `#${slugify(str ? str.toLowerCase() : '')}`;

export default Heading;
