import * as React from 'react';
import { graphql } from 'gatsby';
import Link from './Link';
import CodeBlock from './CodeBlock';
import { Parameter } from '../types/reference';

const REFERENCES: { [key: string]: string } = {
  Promise:
    'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise',
  Error: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error',
  Function:
    'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Function',
};

function Reference() {
  return null; // noop
}

interface PropertyProps {
  name: string;
  optional?: boolean;
  type: string | PropertyProps; // JSON
  parameters?: Parameter[];
}

Reference.Property = function ReferenceProperty({ name, optional, type }: PropertyProps) {
  const parsed = typeof type === 'string' ? JSON.parse(type) : type;

  return (
    <CodeBlock className="language-js" size="sm">
      <code className="pre-code">
        <span className="token keyword">{name}</span>
        {optional && <span className="token punctuation">?</span>}
        <span className="token punctuation">: </span>
        <TypeGenerator parsed={parsed} />
        <span className="token punctuation">;</span>
      </code>
    </CodeBlock>
  );
};

Reference.Parameter = function ReferenceParameter({ parameter }: { parameter: Parameter }) {
  const parsed = typeof parameter.type === 'string' ? JSON.parse(parameter.type) : parameter.type;

  return (
    <>
      <span>{parameter.name}</span>
      {parameter.optional && <span>?</span>}
      <span>: </span>
      <TypeGenerator parsed={parsed} />
    </>
  );
};

Reference.Method = function ReferenceMethod({ name, parameters, type }: PropertyProps) {
  const parsed = typeof type === 'string' ? JSON.parse(type) : type;

  return (
    <CodeBlock className="language-js" size="sm">
      <code className="pre-code">
        <span className="token keyword">{name}</span>
        <span className="token">(</span>
        {parameters?.map((parameter, pi) => [
          <Reference.Parameter key={parameter.name} parameter={parameter} />,
          parameters.length > 1 && pi + 1 !== parameters.length ? ', ' : null,
        ])}
        <span className="token">)</span>
        <span className="token punctuation">: </span>
        <TypeGenerator parsed={parsed} />
        <span className="token punctuation">;</span>
      </code>
    </CodeBlock>
  );
};

Reference.Alias = function ReferenceAlias({ type }: { type: string }) {
  const parsed = typeof type === 'string' ? JSON.parse(type) : type;
  debugger;
  return (
    <CodeBlock className="language-js" size="sm">
      <code className="pre-code">
        <TypeGenerator parsed={parsed} />
      </code>
    </CodeBlock>
  );
};

interface TypeGeneratorParsedProps {
  type: string;
  name?: string;
  types?: TypeGeneratorParsedProps[];
  elementType?: TypeGeneratorParsedProps;
  elements?: TypeGeneratorParsedProps[];
  parameters?: Parameter[];
  declaration: {
    name: string;
    indexSignature: {
      type: TypeGeneratorParsedProps;
      parameters: PropertyProps[];
    }[];
    signatures: TypeGeneratorParsedProps[];
  };
  typeArguments?: TypeGeneratorParsedProps[];
}

function TypeGenerator({ parsed }: { parsed: TypeGeneratorParsedProps }) {
  const out: React.ReactElement[] = [];

  // foo: string;
  if (parsed.type === 'intrinsic') {
    out.push(
      <span key="intrinsic" className="token intrinsic">
        {parsed.name}
      </span>,
    );
  }

  // foo: string[];
  if (parsed.type === 'array' && parsed.elementType) {
    out.push(<TypeGenerator key="array" parsed={parsed.elementType} />);
    out.push(
      <span key="array-punct" className="token punctuation">
        []
      </span>,
    );
  }

  // foo: string | number;
  if (parsed.type === 'union' && parsed.types) {
    const { length } = parsed.types;
    parsed.types.forEach((type, index) => {
      // @ts-ignore
      out.push(<TypeGenerator key={`union-${index}`} parsed={type} />);
      if (index + 1 < length) {
        out.push(
          <span key={`union-${index}-punct`} className="token punctuation">
            &nbsp;|&nbsp;
          </span>,
        );
      }
    });
  }

  // foo: [string, number, boolean]
  if (parsed.type === 'tuple' && parsed.elements) {
    const { length } = parsed.elements;
    out.push(<span key="tuple-open">[</span>);
    parsed.elements.forEach((el, index) => {
      out.push(<TypeGenerator key={`tuple-${index}`} parsed={el} />);
      if (index + 1 < length) {
        out.push(
          <span key={`tuple-${index}-punct`} className="token punctuation">
            ,{' '}
          </span>,
        );
      }
    });
    out.push(<span key="tuple-close">]</span>);
  }

  // foo: Custom
  if (parsed.type === 'reference' && parsed.name) {
    if (REFERENCES[parsed.name]) {
      out.push(
        <span key="reference" className="token string">
          <Link target="_blank" to={REFERENCES[parsed.name]}>
            {parsed.name}
          </Link>
        </span>,
      );
    } else {
      out.push(
        <span key="reference" className="token string">
          <Link to={`/react-native/reference/${parsed.name.toLowerCase()}`}>{parsed.name}</Link>
        </span>,
      );
    }

    if (parsed.typeArguments?.length) {
      out.push(<span>{'<'}</span>);
      const taLength = parsed.typeArguments.length;
      parsed.typeArguments.forEach((argument, ai) => {
        out.push(<TypeGenerator parsed={argument} />);
        if (taLength > 1 && ai + 1 !== taLength) {
          out.push(<span>, </span>);
        }
      });
      out.push(<span>{'>'}</span>);
    }
  }

  // foo: { [key: string]: string }
  if (parsed.type === 'reflection' && parsed?.declaration?.indexSignature) {
    out.push(
      <span key="reflection-open" className="token punctuation">
        {'{ ['}
      </span>,
    );
    parsed.declaration.indexSignature.forEach(signature => {
      signature.parameters.forEach(param => {
        out.push(
          <span key={param.name} className="token string">
            {param.name}
          </span>,
        );
        out.push(
          <span key={`${param.name}-punct`} className="token punctuation">
            :{' '}
          </span>,
        );
        // @ts-ignore
        out.push(<TypeGenerator key={`${param.name}-type`} parsed={param.type} />);
      });
      out.push(
        <span key="reflection-divider" className="token punctuation">
          ]:{' '}
        </span>,
      );
      out.push(<TypeGenerator key="reflection-type" parsed={signature.type} />);
    });

    out.push(
      <span key="reflection-close" className="token punctuation">
        {' }'}
      </span>,
    );
  }

  // () => void
  if (
    parsed.type === 'reflection' &&
    parsed?.declaration?.name === '__type' &&
    parsed?.declaration?.signatures
  ) {
    out.push(
      <span key="reflection-call-open" className="token">
        {'('}
      </span>,
    );
    if (parsed?.declaration?.signatures[0]?.parameters) {
      const paramLength = parsed?.declaration?.signatures[0]?.parameters.length;
      parsed?.declaration?.signatures[0]?.parameters.forEach((parameter, pi) => {
        out.push(<Reference.Parameter key={pi} parameter={parameter} />);
        if (paramLength > 1 && pi + 1 !== paramLength) {
          out.push(<span>, </span>);
        }
      });
    }
    out.push(
      <span key="reflection-call-open" className="token">
        {')'}
      </span>,
    );
    out.push(
      <span key="reflection-call-arrow" className="token punctuation">
        {' => '}
      </span>,
    );
    // @ts-ignore
    out.push(<TypeGenerator parsed={parsed.declaration.signatures[0].type} />);
  }

  return <React.Fragment>{out}</React.Fragment>;
}

export const query = graphql`
  fragment SignatureReference on ReferenceEntitySignatures {
    parameters {
      name
      type
    }
    type
  }
`;

export default Reference;
